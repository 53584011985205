import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/Layout"
import PageCarousel from "../components/PageCarousel"
import { useStaticQuery, graphql } from "gatsby"

export default function GeneratorsCompressors() {
  const imageData = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          extension: { regex: "/(jpg)|(png)|(jpeg)/" }
          relativeDirectory: { eq: "images/generators-compressors" }
        }
      ) {
        edges {
          node {
            base
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  return (
    <>
      <Helmet>
        <title>Generators & Compressors | Service Center</title>
        <meta
          name="description"
          content="We service and repair all types of generators and compressors. Call us now to chat with our team of experts."
        />
      </Helmet>

      <Layout title="Generators & Compressors">
        <section className="overflow-hidden">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div>
                  <p>
                    We service and repair all types of generators and
                    compressors.
                  </p>
                  <p>
                    It is important to replace the oil and filters on these
                    machines regularly. Doing so will keep them running smoothly
                    for years to come.
                  </p>
                  <p>
                    Has your machine completely given up the ghost? We can
                    supply you with a brand new replacement that will last you
                    for years to come.
                  </p>
                  <p>
                    Call us now on <a href="tel:063706926">06 370 6926</a> to
                    chat with our team of experts.
                  </p>
                </div>
              </div>

              <div className="col-lg-6">
                <PageCarousel imageData={imageData} />
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  )
}
